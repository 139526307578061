import React from 'react';
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const darkTheme = createTheme({
    palette: {
      type: 'dark',
    },
  });

export default function SimpleContainer() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <div className="timebox-globe rotate"></div>
        <App />
    </ThemeProvider>
  );
}

ReactDOM.render(<SimpleContainer />, document.getElementById('root'))
