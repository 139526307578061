import React, { Component } from 'react';
import moment from 'moment';

import './timeindicator.css'

// TODO: move this to a util
const percentOfDayRangeComplete = (start, end) => {
  var now = moment();
  start = start || moment(now).startOf('day');
  end = end || moment(now).endOf('day');
  var totalMillisInRange = end.valueOf() - start.valueOf();
  var elapsedMillis = now.valueOf() - start.valueOf();
  // This will bound the number to 0 and 100
  return Math.max(0, Math.min(100, 100 * (elapsedMillis / totalMillisInRange)));
};

const calcOffset = () => {
  return percentOfDayRangeComplete() + '%';  
}

export const TimeIndicator = (props) => {  
    if (!props.teamMates || !props.teamMates.length){
      return null;
    }

    return (
      <span className="TimeIndicator-pointer fadeIn" style={{left: '50%'}} />
    )
}

export class TimeIndicatorRelative extends Component {  

  constructor(props){
    super(props);
    this.state = { time: percentOfDayRangeComplete() };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ offset: calcOffset() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    if (!this.state.offset) {
      return null;
    }

    return (
      <span className="TimeIndicator-pointer fadeIn" style={{left: this.state.offset}} />
    )
  }

}

