import React, { Component } from 'react'
import * as ls from 'local-storage';
import { nanoid } from 'nanoid'
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FancyButton from './components/FancyButton'
import TeamList from './components/TeamList';
import PlaceFinder from './components/PlaceFinder';
import Draw from './components/Draw';
import ToggleButtons from './components/ToggleButtons';

import './App.css'

const percentOfDayRangeComplete = (start, end) => {
  var now = moment();
  start = start || moment(now).startOf('day');
  end = end || moment(now).endOf('day');
  var totalMillisInRange = end.valueOf() - start.valueOf();
  var elapsedMillis = now.valueOf() - start.valueOf();
  // This will bound the number to 0 and 100
  return Math.max(0, Math.min(100, 100 * (elapsedMillis / totalMillisInRange)));
};

const calcOffset = () => {
  return (-percentOfDayRangeComplete() + 50) + '%';
}
export default class App extends Component {
  state = {
    teamMates: [],
    showMenu: false,
    offset: '50%',
    now: null,
    name: '',
    alignment: 'pizza',
  }
  componentDidMount() {
    // Get list of team mates or create placeholder
    const teamMates = ls.get('teamMates') || [];

    this.setState({
      teamMates: teamMates
    })

    this.interval = setInterval(() => this.setState({ offset: calcOffset(), now: moment() }), 1000);

  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  saveteamMate = (e) => {
    e.preventDefault()
    const { teamMates } = this.state
    const nameValue = this.state.name;
    const placeValue = this.state.place;

    if (!nameValue || !placeValue) {
      alert('Please complete all fields');
      return false
    }

    const newteamMateArray = [{
      data: {
        name: nameValue,
        place: placeValue,
      },
      ref: { id: nanoid(10) },
      ts: new Date().getTime() * 10000
    }]

    const optimisticteamMateState = newteamMateArray.concat(teamMates)

    this.setState({
      teamMates: optimisticteamMateState,
      name: '',
      timezone: '',
      place: '',
    })

    ls.set('teamMates', optimisticteamMateState);

    // Hack to save time messing with props to state
    document.getElementsByClassName('MuiBackdrop-root')[0].click();
  }
  deleteteamMate = (e) => {
    const { teamMates } = this.state
    const teamMateId = e.target.dataset.id

    // Optimistically remove teamMate from UI
    const filteredteamMates = teamMates.reduce((acc, current) => {
      const currentId = getteamMateId(current)
      if (currentId === teamMateId) {
        // save item being removed for rollback
        acc.rollbackteamMate = current
        return acc
      }
      // filter deleted teamMate out of the teamMates list
      acc.optimisticState = acc.optimisticState.concat(current)
      return acc
    }, {
      rollbackteamMate: {},
      optimisticState: []
    })

    this.setState({
      teamMates: filteredteamMates.optimisticState
    })

    ls.set('teamMates', filteredteamMates.optimisticState);

  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }
  handleTimezoneChange = (e) => {
    this.setState({ timezone: e.target.value });
  }
  handlePlaceChange = (value) => {
    this.setState({ place: value });
  }
  handleAlignmentChange = (value) => {
    this.setState({ alignment: value });
    console.log('alignment', value);
  }

  render() {
    const { teamMates, offset, now, name, place, alignment } = this.state

    // Default view
    let teamList = (
      <Typography variant="h1" gutterBottom noWrap style={{ textAlign: 'center', fontSize: 'calc((100vw/14))' }}>
        TimeBox.<span role="img" aria-label="Pizza">🍕</span>
      </Typography>
    );

    if (teamMates && teamMates.length) {
      teamList = (
        <React.Fragment>
          <TeamList
            teamMates={teamMates}
            offset={offset}
            now={now}
            deleteAction={this.deleteteamMate.bind(this)}
            alignment={alignment}
          />
        </React.Fragment>
      )
    }

    const formIsCompleted = !!name && !!place;

    return (
      <React.Fragment>
        <ToggleButtons
          onAlignmentChange={this.handleAlignmentChange.bind(this)}
        />
        <div className='app'>
          <div className='teamMate-list'>
            <Draw>
              <form className='teamMate-form' noValidate autoComplete="off">

                <div className="teamMate-form--item">
                  <TextField
                    className='teamMate-create-input'
                    placeholder='Name'
                    name='name'
                    onChange={this.handleNameChange.bind(this)}
                    value={name}
                    style={{ minWidth: 300, paddingTop: 16 }}
                    autoFocus
                  />
                </div>
                <div className="teamMate-form--item">
                  <PlaceFinder
                    onPlaceChange={this.handlePlaceChange.bind(this)}
                  />
                </div>
                <div className="teamMate-form--item">
                  <FancyButton
                    onClick={this.saveteamMate}
                    type="submit"
                    color="blue"
                    disabled={!formIsCompleted}
                  >
                    Add
                </FancyButton>
                </div>
              </form>
            </Draw>
          </div>
          {teamList}
        </div>
      </React.Fragment>
    )
  }
}

function getteamMateId(teamMate) {
  if (!teamMate.ref) {
    return null
  }
  return teamMate.ref.id
}
