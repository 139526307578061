import React from 'react';
import Drawer from '@material-ui/core/Drawer';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

export default function TemporaryDrawer(props) {
  const anchor = 'top';
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const phabStyles = {
      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      position: 'absolute',
      right: 20,
      bottom: 20,
      height: 70,
      width: 70,
    };

   return (
    <div>
        <React.Fragment key={anchor}>
          <Fab 
            style={phabStyles}
            color="primary" 
            aria-label="add"
            onClick={toggleDrawer(anchor, true)}
          >
            <AddIcon />
          </Fab>

          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {props.children}
          </Drawer>
        </React.Fragment>
    </div>
  );
}