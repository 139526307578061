import React from 'react';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import './togglebuttons.css';

export default function ToggleButtons(props) {
    const [alignment, setAlignment] = React.useState('pizza');
    const onAlignmentChange = props.onAlignmentChange;

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            onAlignmentChange(newAlignment);
        }
    };

    return (
        <div className="toggle-buttons">
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="alignment"
            >
                <ToggleButton value="pizza" aria-label="pizza view">
                    <LocalPizzaIcon />
                </ToggleButton>
                <ToggleButton value="relative" aria-label="relative view">
                    <DehazeIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
}