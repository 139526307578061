import React from 'react';
import sortByDate from '../../utils/sortByDate'
import moment from 'moment';
import classNames from 'classnames';

import { TimeIndicator, TimeIndicatorRelative } from '../../components/TimeIndicator';

import DeleteOutline from '@material-ui/icons/DeleteOutline';

import './teamlist.css'

const arrayMover = (arr, offset=0) => {
    //positive offsets move right
    const pivot = (offset < 0? 0: arr.length) - offset % arr.length;
    //positive offsets move left
    //const pivot = (offset < 0? arr.length: 0) + offset % arr.length;
    return arr.slice(pivot).concat(arr.slice(0, pivot));
};

const timeZoneOffset = (data) => {
  let now = moment();
  let here = now.utcOffset();
  let there = data.place.utc_offset_minutes;

  let theOffset = (here - there) / 60;

  if (theOffset === 0) {
    return 0;
  }

  return theOffset;
}

const whatIstheTime = (now, utc_offset_minutes) => {

  let time = now.utcOffset(utc_offset_minutes).format("ddd - hh:mma");

  return time;
}

const TimeBarPizza = ({ name, data, now, children }) => {

  if (!now) {
    return null;
  }

  const leftOffset = (100 / 24 * timeZoneOffset(data)) + '%';

  return (
    <div className="timeBar fadeIn" style={{ left: leftOffset }}>
      <div className="timeBar-wrap">
        <p className="timeBar-text">
          <span className="heavy">{name}</span> - {data.place.description}
        </p>
        <p className="timeBar-time ">
          {whatIstheTime(now, data.place.utc_offset_minutes)}
        </p>
      </div>
      <div className="timeBar-extras">
        {children}
      </div>
    </div>
  )
}

const TimeBarRelative = ({ name, data, now, children }) => {

  if (!now) {
    return null;
  }

  let timesDisplayed;
  const possTimes = ['12am','1am','2am','3am','4am','5am','6am','7am','8am','9am','10am','11am','12pm','1pm','2pm','3pm','4pm','5pm','6pm','7pm','8pm','9pm','10pm','11pm'];
  const possHalfTimes = ['12.30am','1.30am','2.30am','3.30am','4.30am','5.30am','6.30am','7.30am','8.30am','9.30am','10.30am','11.30am','12.30pm','1.30pm','2.30pm','3.30pm','4.30pm','5.30pm','6.30pm','7.30pm','8.30pm','9.30pm','10.30pm','11.30pm'];

  let offsetInHours = timeZoneOffset(data);
  
  // check for a decimal
  if (offsetInHours % 1 === 0){
    timesDisplayed = arrayMover(possTimes, timeZoneOffset(data));
  } else {
    timesDisplayed = arrayMover(possHalfTimes, timeZoneOffset(data));
  }

  let timeLine = timesDisplayed.map((time, i) => {

    let prefix = time.split(/[a-z]/)[0];
    let suffix = time.match(/[a-z]+/g)[0];

    return (
      <div key={i} className="timeBar-wrap--relative--item">
        <p className="timeBar-wrap--relative--item--time">
          <span>{prefix}</span>
          <span>{suffix}</span>
        </p>
      </div>
    )
  });

  return (
    <div className="timeBar timeBar--relative fadeIn">
      <p className="timeBar-text">
        <span className="heavy">{name}</span> - {data.place.description} ({whatIstheTime(now, data.place.utc_offset_minutes)})
      </p>
      <div className="timeBar-wrap--relative">
        {timeLine}
      </div>
      <div className="timeBar-extras">
        {children}
      </div>
    </div>
  )
}


const TeamList = (props) => {
  const { teamMates, deleteAction, now, alignment, offset } = props;

  if (!teamMates || !teamMates.length) {
    // Loading State here
    return null;
  }

  const timeStampKey = 'ts'
  const orderBy = 'desc' // or `asc`
  const sortOrder = sortByDate(timeStampKey, orderBy)
  const teamMatesByDate = teamMates.sort(sortOrder)

  let timeIndicator = (
    <TimeIndicator teamMates={teamMates} />
  );

  if (alignment === 'relative') {
    timeIndicator = (
      <TimeIndicatorRelative teamMates={teamMates} />
    );
  }

    const items = teamMatesByDate.map((teamMate, i) => {
    const { data, ref } = teamMate

    // only show delete button after create API response returns
    let deleteButton
    if (ref) {
      deleteButton = (
        <button data-id={ref.id} onClick={deleteAction}>
          <DeleteOutline style={{ color: '#ef3333' }} />
        </button>
      )
    }

    // Default component
    let component = (
      <div className="teamList-holder" style={{ left: offset }}>
        <TimeBarPizza
          name={data.name}
          data={data}
          now={now}
        >
          {deleteButton}
        </TimeBarPizza>
      </div>
    )

    if (alignment === 'relative') {
      component = (
        <TimeBarRelative
          name={data.name}
          data={data}
          now={now}
        >
          {deleteButton}
        </TimeBarRelative>
      )
    }

    return (
        <div key={i} className='teamList-item'>
          {component}
        </div>
    )

  })

  let classname = classNames(
    'main-view',
    {
      [`main-view--${alignment}`]: alignment
    }
  );

  return (
    <div className={classname}>
      <React.Fragment>
        {timeIndicator}
        {items}
      </React.Fragment>
    </div>
  )

}

export default TeamList;
